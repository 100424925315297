.home-page-container {
  min-height: 100vh;
}
.home-page-home-page {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  max-width: auto;
  box-sizing: border-box;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  border-color: transparent;
  border-radius: 0px 0px 0px 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.home-page-navbar1 {
  width: auto;
  height: auto;
  display: flex;
  padding: 0 64px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.home-page-container1 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  justify-content: space-between;
}
.home-page-column {
  display: flex;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: center;
}
.home-page-icon {
  width: auto;
  height: 73px;
}
.home-page-text {
  align-self: center;
  text-align: center;
}
.home-page-header20 {
  width: 100%;
  height: 577px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-fourunits);
  border-color: transparent;
  margin-right: 0;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-page-column1 {
  width: 100%;
  height: 386px;
  display: flex;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
}
.home-page-content {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 24px;
  flex-direction: column;
}
.home-page-text03 {
  width: 60%;
  height: 100%;
  font-size: 48px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 800;
  line-height: 120.00000476837158%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 24px;
  text-transform: capitalize;
  text-decoration: none;
}
.home-page-text04 {
  width: 50%;
  height: 100%;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  text-align: center;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-text05 {
  width: 50%;
  height: 100%;
  text-align: center;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-actions {
  width: 458px;
  height: 122px;
  display: flex;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  padding-left: 16px 0 0 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  padding-right: 16px 0 0 0;
  flex-direction: column;
  padding-bottom: 16px 0 0 0;
}
.home-page-form {
  display: flex;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 16px;
}
.home-page-input {
  color: rgba(79, 79, 79, 1);
  height: 51px;
  display: flex;
  padding: 12px;
  position: relative;
  font-size: 16px;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 16px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.home-page-button {
  display: flex;
  padding: 12px 24px;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  justify-content: center;
  background-color: #000000;
}
.home-page-text07 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 400px;
  line-height: 150%;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-text08 {
  height: auto;
  align-self: center;
  font-style: Regular;
  text-align: left;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-text09 {
  text-decoration: NONE;
}
.home-page-text10 {
  text-decoration: UNDERLINE;
}
.home-page-testimonial4 {
  width: 100%;
  display: flex;
  padding: 112px 64px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0px;
  justify-content: center;
  background-color: #d8f34e;
}
.home-page-content01 {
  width: 768px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
}
.home-page-stars {
  width: 116px;
  height: 19px;
  position: relative;
  box-sizing: border-box;
  margin-right: 0;
  margin-bottom: 32px;
}
.home-page-text13 {
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 32px;
  text-decoration: none;
}
.home-page-text14 {
  font-weight: 500;
}
.home-page-text15 {
  font-weight: 700;
}
.home-page-text16 {
  font-weight: 500;
}
.home-page-text17 {
  font-weight: 700;
}
.home-page-avatar {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.home-page-avatar-image {
  width: 56px;
  height: 56px;
  position: relative;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 20px;
  margin-bottom: 0;
}
.home-page-avatar-content {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 20px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
}
.home-page-text18 {
  height: auto;
  align-self: auto;
  font-style: normal;
  text-align: left;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0px;
}
.home-page-text19 {
  height: auto;
  align-self: auto;
  font-style: normal;
  text-align: left;
  line-height: 150%;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-divider {
  width: 61px;
  height: 0px;
  position: relative;
  box-sizing: border-box;
  margin-right: 20px;
  margin-bottom: 0;
}
.home-page-webflow-black {
  width: 120px;
  height: 48px;
  position: relative;
  box-sizing: border-box;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-layout134 {
  width: 100%;
  display: flex;
  padding: 112px 64px;
  overflow: hidden;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.home-page-content02 {
  width: 768px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
}
.home-page-section-title {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 24px;
  flex-direction: column;
}
.home-page-text20 {
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 150%;
  margin-right: 0;
  margin-bottom: 16px;
}
.home-page-content03 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
}
.home-page-text22 {
  height: auto;
  font-size: 36px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 120.00000476837158%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 24px;
  letter-spacing: auto;
  text-transform: none;
  text-decoration: none;
}
.home-page-text24 {
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-transform: none;
  text-decoration: none;
}
.home-page-f-a-q9 {
  width: 100%;
  display: flex;
  padding: 112px 64px;
  overflow: hidden;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-fourunits);
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: center;
}
.home-page-section-title1 {
  width: 768px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 80px;
  flex-direction: column;
}
.home-page-text26 {
  height: auto;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  line-height: 120.00000476837158%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 24px;
}
.home-page-text28 {
  height: auto;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-content04 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 80px;
  flex-direction: column;
}
.home-page-row {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 48px;
  flex-direction: column;
}
.home-page-divider1 {
  width: auto;
  height: 1px;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  margin-bottom: 24px;
}
.home-page-content05 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.home-page-text30 {
  width: 500px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 150%;
  margin-right: 64px;
  margin-bottom: 0;
}
.home-page-text31 {
  height: auto;
  flex-grow: 1;
  align-self: auto;
  font-style: normal;
  text-align: left;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-row1 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 48px;
  flex-direction: column;
}
.home-page-divider2 {
  width: auto;
  height: 1px;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  margin-bottom: 24px;
}
.home-page-content06 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.home-page-text32 {
  width: 500px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 150%;
  margin-right: 64px;
  margin-bottom: 0;
}
.home-page-text33 {
  width: 500px;
  height: auto;
  align-self: auto;
  text-align: left;
  margin-bottom: 0;
}
.home-page-text35 {
  height: auto;
  flex-grow: 1;
  align-self: auto;
  font-style: normal;
  text-align: left;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-row2 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 48px;
  flex-direction: column;
}
.home-page-divider3 {
  width: auto;
  height: 1px;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  margin-bottom: 24px;
}
.home-page-content07 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.home-page-text37 {
  width: 500px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 150%;
  margin-right: 64px;
  margin-bottom: 0;
}
.home-page-text38 {
  height: auto;
  flex-grow: 1;
  align-self: auto;
  font-style: normal;
  text-align: left;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-row3 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
}
.home-page-divider4 {
  width: auto;
  height: 1px;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  margin-bottom: 24px;
}
.home-page-content08 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.home-page-text40 {
  width: 500px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 150%;
  margin-right: 64px;
  margin-bottom: 0;
}
.home-page-text41 {
  height: auto;
  flex-grow: 1;
  align-self: auto;
  font-style: normal;
  text-align: left;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-content09 {
  width: 560px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: transparent;
  margin-right: 0;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-page-content10 {
  display: flex;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 24px;
  flex-direction: column;
}
.home-page-text43 {
  height: auto;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  line-height: 129.99999523162842%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 16px;
}
.home-page-text45 {
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-transform: none;
  text-decoration: none;
}
.home-page-button1 {
  display: flex;
  padding: 12px 24px;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  justify-content: center;
}
.home-page-text47 {
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 150%;
  margin-right: 0;
  margin-bottom: 0;
}
.home-page-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-page-container2 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-page-image {
  height: 2rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-page-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.home-page-text49 {
  margin-left: 0px;
}
.home-page-text50 {
  margin-left: var(--dl-space-space-unit);
}
.home-page-text51 {
  margin-left: var(--dl-space-space-unit);
}
.home-page-text52 {
  margin-left: var(--dl-space-space-unit);
}
.home-page-text53 {
  margin-left: var(--dl-space-space-unit);
}
.home-page-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-page-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-page-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-page-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-page-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-page-icon6 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 1200px) {
  .home-page-home-page {
    height: auto;
  }
  .home-page-navbar1 {
    width: auto;
    height: auto;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .home-page-container1 {
    height: auto;
    align-self: center;
    align-items: stretch;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .home-page-icon {
    width: auto;
  }
  .home-page-text {
    font-size: 48px;
    align-self: center;
    font-style: normal;
    font-family: Inter;
    font-weight: 800;
    letter-spacing: auto;
  }
  .home-page-header20 {
    width: 100%;
    height: 566px;
    padding: var(--dl-space-space-threeunits);
    align-self: center;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .home-page-column1 {
    width: 917px;
    height: 377px;
    align-self: center;
    padding-right: 0px;
  }
  .home-page-content {
    width: auto;
  }
  .home-page-text03 {
    width: auto;
  }
  .home-page-text04 {
    width: 80%;
    align-self: center;
    text-align: center;
  }
  .home-page-text05 {
    width: 80%;
    align-self: center;
    text-align: center;
  }
  .home-page-actions {
    width: 550px;
    height: 151px;
    align-self: center;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-page-button {
    background-color: #000000;
  }
  .home-page-text09 {
    text-decoration: none;
  }
  .home-page-text10 {
    text-decoration: underline;
  }
  .home-page-testimonial4 {
    width: 100%;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-page-text13 {
    font-family: Inter;
  }
  .home-page-layout134 {
    width: 100%;
  }
  .home-page-f-a-q9 {
    width: 100%;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-page-section-title1 {
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-page-text26 {
    text-align: center;
  }
  .home-page-text28 {
    text-align: center;
  }
  .home-page-content09 {
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-page-text43 {
    text-align: center;
  }
  .home-page-text45 {
    text-align: center;
  }
}
@media(max-width: 991px) {
  .home-page-navbar1 {
    width: auto;
    height: auto;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-page-icon {
    width: auto;
  }
  .home-page-header20 {
    width: 100%;
    margin-right: 0px;
  }
  .home-page-column1 {
    width: 100%;
  }
  .home-page-content {
    width: 100%;
  }
  .home-page-testimonial4 {
    width: 100%;
  }
  .home-page-layout134 {
    width: 100%;
  }
  .home-page-f-a-q9 {
    width: auto;
  }
  .home-page-section-title1 {
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-page-text26 {
    text-align: center;
  }
  .home-page-text28 {
    text-align: center;
  }
  .home-page-content09 {
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-page-text43 {
    text-align: center;
  }
  .home-page-text45 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .home-page-home-page {
    width: auto;
  }
  .home-page-navbar1 {
    width: 100%;
    height: auto;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-page-container1 {
    width: auto;
    height: 100%;
    justify-content: center;
  }
  .home-page-column1 {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .home-page-content {
    width: 100%;
    height: 530px;
    align-self: center;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 0px;
    flex-direction: column;
    padding-bottom: 4px;
    justify-content: center;
  }
  .home-page-text03 {
    width: auto;
    height: auto;
    font-size: 40px;
  }
  .home-page-text04 {
    width: auto;
    height: auto;
    align-self: center;
  }
  .home-page-text05 {
    width: 90%;
  }
  .home-page-actions {
    width: 100%;
    height: 137px;
    align-self: center;
    align-items: center;
    justify-content: flex-end;
  }
  .home-page-testimonial4 {
    width: 100%;
    height: 263px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-page-content01 {
    width: 100%;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-page-layout134 {
    width: 100%;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-page-content02 {
    width: 495px;
    height: 333px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-page-section-title {
    width: 100%;
    height: 289px;
    align-self: center;
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-page-f-a-q9 {
    width: 100%;
    align-self: center;
  }
  .home-page-section-title1 {
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-page-content05 {
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-page-text30 {
    width: 100%;
    align-self: stretch;
    line-height: 150%;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-row1 {
    flex-direction: column;
  }
  .home-page-content06 {
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-page-text32 {
    width: 100%;
    line-height: 150%;
    margin-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-content07 {
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-page-text37 {
    width: 100%;
    margin-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-content08 {
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-page-text40 {
    width: 100%;
    margin-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-content09 {
    width: 100%;
  }
  .home-page-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-page-image {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-page-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-page-container3 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-page-text54 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-page-home-page {
    padding-top: 0px;
  }
  .home-page-navbar1 {
    width: 100%;
    align-self: center;
    margin-top: 2px;
    align-items: center;
    margin-bottom: 5px;
    justify-content: center;
  }
  .home-page-container1 {
    width: auto;
    height: auto;
  }
  .home-page-icon {
    width: auto;
    height: var(--dl-size-size-small);
  }
  .home-page-text {
    font-size: 32;
  }
  .home-page-header20 {
    width: auto;
    height: 364px;
    max-width: 100%;
    align-self: center;
    align-items: center;
    padding-top: 0px;
    margin-right: 0px;
    flex-direction: row;
    padding-bottom: 0px;
    justify-content: center;
  }
  .home-page-column1 {
    width: 100%;
    height: 100%;
    align-self: center;
    padding-right: 0px;
    flex-direction: column;
    justify-content: center;
  }
  .home-page-content {
    width: 100%;
    height: 338px;
    max-width: var(--dl-size-size-maxwidth);
    align-self: center;
    align-items: center;
    margin-bottom: 0px;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-page-text03 {
    width: auto;
    height: 100%;
    font-size: 24px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Inter;
    font-weight: 800;
    line-height: 120%;
    margin-bottom: auto;
    letter-spacing: auto;
    text-transform: capitalize;
    text-decoration: none;
  }
  .home-page-text04 {
    width: auto;
    height: auto;
    font-size: 14px;
    font-style: normal;
    margin-top: 0;
    text-align: center;
    line-height: 120%;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-text05 {
    width: auto;
    height: auto;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-page-actions {
    width: auto;
    height: 100px;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-page-form {
    width: 100%;
    height: 87px;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-input {
    width: 100%;
    height: 34px;
    padding: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-page-button {
    width: 100%;
    height: 40px;
    align-self: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .home-page-text07 {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 14px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Inter;
    line-height: 150%;
    letter-spacing: 100%;
  }
  .home-page-text08 {
    width: 100%;
    height: auto;
    font-size: 14px;
    align-self: center;
    text-align: center;
    line-height: 100%;
  }
  .home-page-testimonial4 {
    width: 100%;
    height: 100%;
    max-width: var(--dl-size-size-maxwidth);
    align-self: center;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .home-page-content01 {
    width: 100%;
    height: 372px;
    padding: var(--dl-space-space-twounits);
    padding-top: 36px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #d8f34e;
  }
  .home-page-stars {
    align-self: center;
  }
  .home-page-text13 {
    width: 90%;
    align-self: center;
  }
  .home-page-avatar {
    width: 406px;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-page-avatar-content {
    align-items: center;
    margin-right: 0px;
    justify-content: center;
  }
  .home-page-text18 {
    font-size: 14px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 150%;
    text-transform: none;
    text-decoration: none;
  }
  .home-page-webflow-black {
    align-self: center;
  }
  .home-page-layout134 {
    width: 100%;
    height: 370px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-page-content02 {
    width: auto;
    height: 321px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-page-section-title {
    width: auto;
    height: 293px;
    align-self: center;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }
  .home-page-content03 {
    width: 100%;
    height: 260px;
    align-self: center;
  }
  .home-page-text22 {
    font-size: 32px;
    align-self: center;
    line-height: 120%;
  }
  .home-page-text24 {
    align-self: center;
  }
  .home-page-f-a-q9 {
    width: 100%;
    height: 1868px;
    max-width: var(--dl-size-size-maxwidth);
    padding-top: 13px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .home-page-section-title1 {
    width: 100%;
    margin-bottom: 0px;
  }
  .home-page-text26 {
    text-align: center;
  }
  .home-page-text28 {
    text-align: center;
  }
  .home-page-content04 {
    width: 100%;
    height: 100%;
    align-self: center;
    margin-top: 0px;
    align-items: stretch;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .home-page-row {
    height: auto;
    margin-bottom: 4px;
  }
  .home-page-content05 {
    flex-direction: column;
  }
  .home-page-text30 {
    width: auto;
    font-size: 18px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 150%;
    padding-bottom: var(--dl-space-space-unit);
    text-transform: none;
    text-decoration: none;
  }
  .home-page-row1 {
    margin-bottom: 0px;
  }
  .home-page-content06 {
    flex-direction: column;
  }
  .home-page-text32 {
    width: auto;
    font-size: 18px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 150%;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-text33 {
    width: auto;
    font-size: 18px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 150%;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-text35 {
    font-family: Inter;
  }
  .home-page-row2 {
    margin-bottom: 0px;
  }
  .home-page-content07 {
    flex-direction: column;
  }
  .home-page-text37 {
    width: 100%;
    font-size: 18px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 150%;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-content08 {
    align-self: stretch;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-page-text40 {
    width: auto;
    font-size: 18px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 150%;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-page-content09 {
    width: 100%;
    height: 395px;
    max-width: var(--dl-size-size-maxwidth);
    align-self: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: center;
  }
  .home-page-content10 {
    width: 100%;
    max-width: var(--dl-size-size-maxwidth);
    align-self: center;
  }
  .home-page-text43 {
    width: auto;
    align-self: center;
    text-align: center;
  }
  .home-page-text45 {
    font-size: 16px;
    align-self: center;
  }
  .home-page-footer {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .home-page-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-page-container3 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-page-text54 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
