:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: rgba(0, 0, 0, 1);
  --dl-color-gray-white: rgba(255, 255, 255, 1);
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #0e49b9ff;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
}
.aText {
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.aH1 {
  font-size: 48px;
  font-family: Inter;
  font-weight: 800;
  text-transform: capitalize;
  text-decoration: none;
}
.h2 {
  font-size: 36px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: auto;
  text-transform: none;
  text-decoration: none;
}
.textMediumMedium {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500px;
  font-stretch: normal;
}
.textMediumLight {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 300px;
  font-stretch: normal;
}
.textTinyExtraBold {
  font-size: 12px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 800px;
  font-stretch: normal;
}
.textSmallLink {
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
  font-stretch: normal;
}
.textTinyLight {
  font-size: 12px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 300px;
  font-stretch: normal;
}
.textLargeBold {
  font-size: 20px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textMediumNormal {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
  font-stretch: normal;
}
.textMediumSemibold {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 600px;
  font-stretch: normal;
}
.textTinyBold {
  font-size: 12px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textRegularLink {
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
  font-stretch: normal;
}
.textMediumBold {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textTinyMedium {
  font-size: 12px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500px;
  font-stretch: normal;
}
.headingxlarge {
  font-size: 48px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.headinglarge {
  font-size: 40px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textRegularBold {
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textRegularExtraBold {
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 800px;
  font-stretch: normal;
}
.textLargeNormal {
  font-size: 20px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
  font-stretch: normal;
}
.headingxsmall {
  font-size: 20px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textLargeMedium {
  font-size: 20px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500px;
  font-stretch: normal;
}
.textTinyLink {
  font-size: 12px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
  font-stretch: normal;
}
.otherTagline {
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 600px;
  font-stretch: normal;
}
.textRegularNormal {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
}
.textSmallLight {
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 300px;
  font-stretch: normal;
}
.textSmallNormal {
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
  font-stretch: normal;
}
.headingxxlarge {
  font-size: 56px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textRegularSemibold {
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 600px;
  font-stretch: normal;
}
.textSmallBold {
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textRegularMedium {
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500px;
  font-stretch: normal;
}
.textMediumLink {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
  font-stretch: normal;
}
.headingsmall {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textSmallExtraBold {
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 800px;
  font-stretch: normal;
}
.textTinySemibold {
  font-size: 12px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 600px;
  font-stretch: normal;
}
.textLargeExtraBold {
  font-size: 20px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 800px;
  font-stretch: normal;
}
.textLargeLight {
  font-size: 20px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 300px;
  font-stretch: normal;
}
.headingmedium {
  font-size: 32px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textLargeSemibold {
  font-size: 20px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 600px;
  font-stretch: normal;
}
.textSmallSemibold {
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 600px;
  font-stretch: normal;
}
.textMediumExtraBold {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700px;
  font-stretch: normal;
}
.textTinyNormal {
  font-size: 12px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
  font-stretch: normal;
}
.textLargeLink {
  font-size: 20px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400px;
  font-stretch: normal;
}
.textRegularLight {
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 300px;
  font-stretch: normal;
}
.textSmallMedium {
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500px;
  font-stretch: normal;
}
